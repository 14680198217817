import * as React from "react";
import Header from "../../Components/Header/Header";
import PartnershipContent from "../../Components/PartnershipContent/PartnershipContent";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

const PartnershipPage = () => {
  return (
    <>
      <Seo locale="en-US" page="partnership" />
      <Header lang="en-US" />
      <HamburgerMenu />
      <PartnershipContent lang="en-US" />
      <Footer lang="en-US" />
    </>
  );
};

export default PartnershipPage;
