import * as React from "react";
import "./PartnershipContent.scss";
import { Col, Container, Row } from "react-bootstrap";
import PartnershipTitle from "./PartnershipTitle";
import query from "../PartnershipContent/query";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { filterDataByLanguage } from "../../Utils/Language";
import PropTypes from "prop-types";

const PartnershipContent = (props) => {
  const data = query();
  const partnershipData = filterDataByLanguage(
    props.lang,
    data.allContentfulPartnership
  );

  function createMarkup() {
    return { __html: partnershipData.magentoDescription.magentoDescription };
  }

  return (
    <div className="partnership-content-container">
      <Container>
        {partnershipData && (
          <PartnershipTitle
            className="partnership-title"
            title={partnershipData.title}
          />
        )}
      </Container>
      {partnershipData && (
        <div className="partnership-main-img-wrapper pb-lg-5">
          <ResponsiveImage
            desktopImage={partnershipData.mainImage}
            mobileImage={partnershipData.mainImageSmall}
            alt={partnershipData.mainImage.file.title}
            className="partnership-main-img img-lg-fluid"
          />
        </div>
      )}
      <Container className="partnership-details-container">
        <Row className="justify-content-between">
          <Col className="partnership-details-element pb-lg-5 pe-lg-3 col-lg-5 col-12 single">
            <div className="partnership-details-image-container">
              <GatsbyImage
                className="partnership-details-image "
                image={
                  partnershipData && getImage(partnershipData.magentoLogos[0])
                }
                alt={
                  partnershipData && partnershipData.magentoLogos[0].file.title
                }
              />
              <GatsbyImage
                className="partnership-details-image"
                image={
                  partnershipData && getImage(partnershipData.magentoLogos[1])
                }
                alt={
                  partnershipData && partnershipData.magentoLogos[1].file.title
                }
              />
              <GatsbyImage
                className="partnership-details-image"
                image={
                  partnershipData && getImage(partnershipData.magentoLogos[2])
                }
                alt={
                  partnershipData && partnershipData.magentoLogos[2].file.title
                }
              />
            </div>
            <div className="partnership-details-title">
              {partnershipData && partnershipData.magentoTitle}
            </div>
            <div className="partnership-details-description">
              {partnershipData && (
                <div dangerouslySetInnerHTML={createMarkup()} />
              )}
            </div>
          </Col>
          <Col className="partnership-details-element pb-lg-5 col-lg-5 col-12 single">
            <div className="partnership-details-image-container">
              <GatsbyImage
                className="partnership-details-image-aws"
                image={partnershipData && getImage(partnershipData.geminiLogo)}
                alt={partnershipData && partnershipData.geminiLogo.file.title}
              />
            </div>
            <div className="partnership-details-title">
              {partnershipData && partnershipData.geminiTitle}
            </div>
            <div className="partnership-details-description">
              {partnershipData &&
                partnershipData.geminiDescription.geminiDescription}
            </div>
          </Col>
          <Col className="partnership-details-element col-lg-5 col-12 single">
            <div className="partnership-details-image-container">
              <GatsbyImage
                className="partnership-details-image-aws"
                image={partnershipData && getImage(partnershipData.awsLogo)}
                alt={partnershipData && partnershipData.awsLogo.file.title}
              />
            </div>
            <div className="partnership-details-title">
              {partnershipData && partnershipData.awsTitle}
            </div>
            <div className="partnership-details-description">
              {partnershipData && partnershipData.awsDescription.awsDescription}
            </div>
          </Col>
          <Col className="partnership-details-element pe-lg-3 ps-lg-3 col-lg-5 col-md-12 col-12 single">
            <Container className="partnership-details-image-container">
              <GatsbyImage
                className="partnership-details-image-cafoscari"
                image={
                  partnershipData && getImage(partnershipData.caFoscariLogo)
                }
                alt={
                  partnershipData && partnershipData.caFoscariLogo.file.title
                }
              />
            </Container>
            <div className="partnership-details-title">
              {partnershipData && partnershipData.caFoscariTitle}
            </div>
            <div className="partnership-details-description">
              {partnershipData &&
                partnershipData.caFoscariDescription.caFoscariDescription}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

function ResponsiveImage(props) {
  const images = withArtDirection(getImage(props.desktopImage), [
    {
      media: "(max-width: 960px)",
      image: getImage(props.mobileImage),
    },
  ]);
  return (
    <GatsbyImage
      alt={props.alt}
      image={images}
      imgClassName={props.className}
    />
  );
}

ResponsiveImage.propTypes = {
  desktopImage: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

PartnershipContent.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default PartnershipContent;
