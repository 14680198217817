import * as React from "react";
import { useEffect, useState } from "react";
import "./PartnershipContent.scss";

const PartnershipContent = (props) => {
  function getWindowDimensions() {
    if (typeof window === "undefined") {
      return {
        width: 0,
        height: 0,
      };
    }

    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (windowDimensions.width > 810) {
    const titleForDesktop = props.title.split(".");
    return (
      <div>
        <h1 className="partnership-title">{titleForDesktop[0]}.</h1>
        <h1 className="partnership-title">{titleForDesktop[1]}.</h1>
      </div>
    );
  }

  return (
    <div>
      <h1 className="partnership-title">{props.title}</h1>
    </div>
  );
};

export default PartnershipContent;
